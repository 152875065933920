/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prázdna stránka"}>
        <SiteHeader />

        <Column className="pb--60 pt--80" name={"wstęp"}>
          
          <ColumnWrap className="column__flex --left el--1 pb--08 pl--0 pr--0 pt--08 flex--center" style={{"maxWidth":1430}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--86 w--300 pl--0" style={{"maxWidth":687}} content={"Pusta strona"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"dke33rwcw2s"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Tytuł"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/160/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/i/template/160/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/160/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/160/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/160/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/160/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box pl--0" content={"<span style=\"color: var(--color-dominant);\">Alicja Wrona</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":384}} content={"<span style=\"color: var(--color-dominant);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":384}} content={"<span style=\"color: var(--color-dominant);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">+48 797 811 2X0<br>Pańska 18, Warszawa</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">REGON: 12345678<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}