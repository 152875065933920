/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Button, Subtitle } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--50 pt--40" name={"wstęp"}>
          
          <ColumnWrap className="column__flex --left el--2 pb--08 pl--0 pr--0 pt--08 flex--center" style={{"maxWidth":1430}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 pb--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":615}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/160/img-1_s=350x_.jpg"} svg={false} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/160/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/160/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/160/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/160/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/160/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--86 w--300" style={{"maxWidth":687}} content={"Accounting, tax records, and <span style=\\\"font-style: italic;\\\">payroll</span> processing."}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":632}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Oferta usług"}>
              </Button>

              <Subtitle className="subtitle-box fs--20 mt--25" content={"⭐ ⭐ ⭐ ⭐ ⭐&nbsp; 4.9 / 5 — Google Reviews"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ottx09 pb--60 pr--0 pt--60" name={"informacje"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/160/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/160/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/160/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/160/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/160/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/160/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/160/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/160/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --right el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1430}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s5 --left pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Accounting work from tax returns to financial auditing</span>"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: white;\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn1 btn-box--shape5 btn-box--cColor1" content={"Oferta usług"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"cennik"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--4 pb--06 pt--06" columns={"4"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/d26d4fea8006402c8dfda26274d3eaba.svg"} sizes={"100vw"} style={{"maxWidth":73}} srcSet={""}>
              </Image>

              <Title className="title-box fs--26 mt--12" content={"Dokumentacja księgowa"}>
              </Title>

              <Text className="text-box mt--16" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/d26d4fea8006402c8dfda26274d3eaba.svg"} sizes={"100vw"} style={{"maxWidth":73}} srcSet={""}>
              </Image>

              <Title className="title-box fs--26 mt--12" content={"Tax records"}>
              </Title>

              <Text className="text-box mt--16" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/d26d4fea8006402c8dfda26274d3eaba.svg"} sizes={"100vw"} style={{"maxWidth":73}} srcSet={""}>
              </Image>

              <Title className="title-box fs--26 mt--12" content={"Payroll processing"}>
              </Title>

              <Text className="text-box mt--16" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/d26d4fea8006402c8dfda26274d3eaba.svg"} sizes={"100vw"} style={{"maxWidth":73}} srcSet={""}>
              </Image>

              <Title className="title-box fs--26 mt--12" content={"Advisory"}>
              </Title>

              <Text className="text-box mt--16" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informacje-2"}>
          
          <ColumnWrap className="column__flex --right el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1430}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" animS={"3"} style={{"maxWidth":""}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/160/img-2_s=350x_.jpg"} svg={false} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/160/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/160/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/160/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/160/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/160/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5 --left pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"5"} style={{"maxWidth":600}}>
              
              <Subtitle className="subtitle-box" content={"Ponad 15 lat doświadczenia"}>
              </Subtitle>

              <Title className="title-box fs--72" content={"Comprehensive <span style=\\\"font-style: italic;\\\">fiscal</span> representation."}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":486}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/160/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/i/template/160/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/160/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/160/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/160/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/160/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box pl--0" content={"<span style=\"color: var(--color-dominant);\">Alicja Wrona</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":384}} content={"<span style=\"color: var(--color-dominant);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":384}} content={"<span style=\"color: var(--color-dominant);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">+48 797 811 2X0<br>Pańska 18, Warszawa</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">REGON: 12345678<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}